<template>
  <CSidebar
    aside
    :show="$store.state.asideShow"
    @update:show="(val) => $store.commit('set', ['asideShow', val])"
    colorScheme="light"
    overlaid
    size="lg"
  >
    <CSidebarClose @click.native="$store.commit('closeAside')"/>
    <br><br>
    <div class="container">
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Tipo pago</th>
            <th>Cantidad</th>
            <th>Acumulado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in reportPaytment" :key="index">
            <td>{{item.value}}</td>
            <td>{{item.quantity}}</td>
            <td>{{item.amount}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="2" align="right">TOTAL</td>
            <td>{{cpTotal}}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </CSidebar>
</template>

<script>
export default {
  name: 'TheAside',
  props:{
    reportPaytment:{},
  },
  computed: {
    cpTotal(){
      let tot = 0;
      this.reportPaytment.forEach(element => {
        tot += parseFloat(element.amount);
      });
      return parseFloat(tot).toFixed(2)
    }
  },
}
</script>
