<template>
  <div>
    <CCard>
      <CCardHeader>
        <i class="fa fa-user"></i>&nbsp; Filtar por persona</CCardHeader
      >
      <CCardBody>
        <CRow>
          <CCol lg="4" md="4" xs="12" sm="12">
            <cSelectForm
              label="Seleccione una persona"
              :options="dataPeople"
              placeholder="Escoja una persona"
              :value.sync="filter.people"
            />
          </CCol>
          <CCol lg="1" md="1" xs="12" sm="12">
            <button
              type="button"
              style="margin-top: 2em"
              :class="btnClasses"
              @click="mtdSearchTickets"
            >
              <i class="fas fa-search"></i>
            </button>
          </CCol>
          <CCol lg="4" md="4" xs="12" sm="12">
            <CInput
              label="Tickets pendientes de pago"
              placeholder=""
              disabled
              v-model="pending"
            />
          </CCol>
          <CCol lg="1" md="1" xs="12" sm="12">
            <button
              type="button"
              style="margin-top: 2em"
              :class="btnClasses"
              :disabled="pending > 0 && filter.people != 0 ? false : true"
              @click="mtdValidPayment"
            >
              <i class="fas fa-dollar-sign"></i>
            </button>
          </CCol>
          <CCol lg="1" md="1" xs="12" sm="12">
            <button type="button" style="margin-top: 2em" :class="btnClasses" @click="mtdViewReport">
              <i class="fas fa-table"></i>
            </button>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CRow>
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de tickets"
          icon="fas fa-ticket"
          btn_name="Ticket"
          :size="'md'"
          :button_new="false"
          :actions="false"
          :withActions="'10%'"
          :buttonEdit="false"
          :buttonDelete="false"
        />
      </CCol>
    </CRow>

    <CModalForm
      :size="'md'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
    >
      <CRow>
        <CCol sm="6">
            <CInput
              label="Número de tickets a pagar"
              placeholder="Número de tickets"
              v-model="num"
            />
        </CCol>
        <CCol sm="6">
          <cSelectForm
              label="Seleccione un metodo"
              :options="payments"
              placeholder="Escoja un metodo"
              :value.sync="payment"
            />
        </CCol>
      </CRow>
    </CModalForm>
    <TheAside :reportPaytment="reportPaytment" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";
import moment from "moment";
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import TheAside from './component/aside.vue'
const fields = [
  { key: "Id", _style: "width:3%" },
  { key: "name", label: "Participante", _style: "width:15%;" },
  { key: "number", label: "Número de ticket", _style: "width:5%;" },
  { key: "status", label: "Pago", _style: "width:5%;" },
];
export default {
  name: "v-sorteos",
  components: { CTableWrapper, cSelectForm,CModalForm, TheAside },
  data() {
    return {
      fields,
      dataPeople: [],
      filter: {
        people: 0,
      },
      data: [],
      pending: 0,

      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      payments:[
        {value:1,label:'Yape'},
        {value:2,label:'Plin'},
        {value:3,label:'Efectivo'}
      ],
      payment:null,
      num:"",
      reportPaytment:[],
      /** aside */
      showAside: false,
    };
  },
  computed: {
    btnClasses() {
      return [`w-100 btn btn-${this.color || "primary"}`];
    },
    cp_button() {},
  },
  created() {
    this.mtdGetData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdGetData: function () {
      this.get({
        url: this.$store.getters.get__url + "/sorteos",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.dataPeople = response.people;
          this.mtdSearchTickets();
        })
        .catch((errors) => {});
    },
    mtdSearchTickets: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/sorteos/ticket/" +
          this.filter.people,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          console.log(response);
          this.data = response.ticket;
          this.pending = response.count;
        })
        .catch((errors) => {});
    },
    mtdValidPayment: function () {
      this.modal.modal_form = true;
      this.modal.action = "store";
      this.modal.action == "store"
        ? (this.modal.title = "Registrar pago")
        : (this.modal.title = "Editar Usuario");
        this.num=this.pending;
    },
    mtd_action: function () {
      this.post({
        url: this.$store.getters.get__url + "/sorteos/payment",
        token: this.$store.getters.get__token,
        params:{
            num: this.num,
            payment: this.payment,
            people: this.filter.people
        }
      })
        .then((response) => {
          console.log(response);
          if (response.state == 0) {
            this.modal.modal_form = false;
            this.mtdGetData();
          }
        })
        .catch((errors) => {});
    },
    mtdViewReport: function(){
      this.get({
        url: this.$store.getters.get__url + "/sorteos/payment",
        token: this.$store.getters.get__token,        
      })
        .then((response) => {
          this.reportPaytment=[];
          this.reportPaytment.push({quantity:response.yape, value:'Yape',amount:parseFloat(response.yape * 5).toFixed(2) });
          this.reportPaytment.push({quantity:response.plin, value:'Plin',amount:parseFloat(response.plin * 5).toFixed(2) });
          this.reportPaytment.push({quantity:response.efec, value:'Efectivo',amount:parseFloat(response.efec * 5).toFixed(2) });
          this.$store.commit('toggle', 'asideShow')
        })
        .catch((errors) => {});
    },
    mtdCloseAside: function(){
      // this.showAside=false;
      // console.log(this.showAside);
    }
  },
};
</script>

<style lang="scss" scoped></style>
